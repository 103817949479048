
import CSearchList from '@/components/c-search-list'
import cButtonGroup from '@/components/c-button-group'
import CTable from '@/components/c-table'
import { getIndexConfig, getTableConfig } from './config'
import { ElMessageBox, ElMessage } from 'element-plus'
import { defineComponent, toRefs, reactive, onMounted, ref } from 'vue'
import limitsDialog from './limits-dialog.vue'
import resetPassDialog from './components/reset-password.vue'
import editPassDialog from './components/edit-password.vue'
import { btnName } from '@/utils/index'

import {
  getList,
  disableAccountApi,
  enableAccountApi,
  dropdownOfEnterprise
} from './api'
export default defineComponent({
  components: {
    CSearchList,
    CTable,
    limitsDialog,
    resetPassDialog,
    editPassDialog,
    cButtonGroup
  },
  setup() {
    const remoteData = reactive({
      options: [],
      isLoading: false,
      remoteMethod: val => {
        dropdownOfEnterprise({ value: val }).then(res => {
          remoteData.options = res.data.map(item => {
            return {
              label: item.value,
              value: item.key
            }
          })
        })
      }
    })
    const data = reactive({
      editData: {},
      resetData: {},
      resetVisible: false,
      editVisible: false,
      ...getIndexConfig({ ...toRefs(remoteData) })
    })
    const limits = ref<HTMLElement | any>(null)
    // 表单组件变量
    const tableConfig = reactive({
      ...getTableConfig()
    })
    /**
     * @description:列表接口
     * @return {void}
     */
    const getListApi = () => {
      const obj = Object.assign(
        { ...data.queryForm },
        {
          currPage: tableConfig.pagination.currentPage,
          pageSize: tableConfig.pagination.pageSize
        }
      )
      getList(obj).then(res => {
        tableConfig.data = res.data.list.map(item => {
          item.accountStatusText = item.accountStatus === 0 ? '禁用' : '正常'
          return item
        })
        tableConfig.pagination.total = res.data.total
      })
    }
    /**
     * @description: 重置事件
     * @param {*} val
     * @return {void}
     */
    const handleReset = (val: object) => {
      data.queryForm = val
      // getListApi()
      // console.log(val)
    }
    /**
     * @description: 查询事件
     * @param {*} params
     * @return {viod}
     */
    const handleSearch = (params: object) => {
      data.queryForm = Object.assign({}, params)
      getListApi()
    }
    /**
     * @description: 翻页
     * @param {*} val  当前页数
     * @return {void}
     */
    const pageCurrentChange = (val: number) => {
      tableConfig.pagination.currentPage = val
      getListApi()
    }
    /**
     * @description: 每页条数
     * @param {*} val 数量
     * @return {void}
     */
    const pageSizeChange = (val: number) => {
      tableConfig.pagination.currentPage = 1
      tableConfig.pagination.pageSize = val
      getListApi()
    }
    /**
     * @description: 二次确认框
     * @param {*} title 提示信息
     * @param {*} name  标题
     * @param {*} btnName 按钮名字
     * @return {promise}
     */
    const confirm = (
      title: string,
      name: string,
      btnName: string
    ): Promise<string> => {
      return ElMessageBox.confirm(title, name, {
        confirmButtonText: btnName,
        showCancelButton: false,
        type: 'warning'
      })
    }
    /**
     * @description:重置确认框
     * @param {*} row  当条数据
     * @return {void}
     */
    const resetPass = (row: Record<string, any>) => {
      // console.log(row, 'row')
      confirm(
        `确定要重置 ${row.enterpriseName || '企业名称'}的管理员账号吗？`,
        '重置密码',
        '确定重置'
      )
        .then(() => {
          data.resetData = row

          data.resetVisible = true
          // resetPasswordAccountApi({
          //   uuid: row.accountId
          // }).then((res: any) => {
          //   getListApi()
          //   ElMessage.success(res.msg || '重置成功!')
          // })
        })
        .catch(() => {})
    }
    /**
     * @description:禁用确认框
     * @param {*} row 当条信息
     * @return {void}
     */
    const disableAccount = async (row: Record<string, any>) => {
      // let h = confirm

      confirm(
        `确定要禁用 ${row.enterpriseName ||
          '企业名称'} 的管理员账号吗？禁用后企业管理员则无法登录出租车SaaS运营管理平台`,
        '禁用管理员账号',
        '确定禁用'
      )
        .then(() => {
          disableAccountApi({ uuid: row.accountId }).then(
            (res: Record<string, any>) => {
              ElMessage.success(res.msg || '禁用成功！')
              getListApi()
            }
          )
        })
        .catch(() => {})
    }
    /**
     * @description:启用确认框
     * @param {*} row 当条信息
     * @return {void}
     */
    const activatePass = (row: Record<string, any>) => {
      confirm(
        `确定要启用 ${row.enterpriseName ||
          '企业名称'} 的管理员账号吗？启用后企业可通过管理员账号登录出租车SaaS运营管理平台`,
        '启用管理员账号',
        '确定启用'
      )
        .then(() => {
          enableAccountApi({ uuid: row.accountId }).then((res: any) => {
            getListApi()
            ElMessage.success(res.msg || '启用成功！')
          })
        })
        .catch(() => {})
    }
    const editPassDialog = row => {
      data.editData = JSON.parse(JSON.stringify(row))
      data.editVisible = true
    }
    /**
     * @description:操作按钮事件
     * @param {*} type  类别
     * @param {*} row 当条数据
     * @return {void}
     */
    const btnHandle = (type: string, row: Record<string, any>) => {
      type === 'disable' && disableAccount(row)
      type === 'reset' && resetPass(row)
      type === 'activate' && activatePass(row)
      type === 'editName' && editPassDialog(row)
      console.log(type, 'row')
      if (type === 'limits' && row) {
        data.rowData = JSON.parse(JSON.stringify(row))
        limits.value.dialogVisible = true
      }
    }
    const handleCloseDialog = (s, key: string) => {
      data[key] = s
      getListApi()
    }
    onMounted(() => {
      getListApi()
    })
    return {
      limits,
      handleReset,
      handleSearch,
      pageCurrentChange,
      pageSizeChange,
      btnHandle,
      btnName,
      handleCloseDialog,
      tableConfig,
      ...toRefs(data)
    }
  }
})
