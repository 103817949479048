/*
 * @Description: 复制粘贴组件
 * @Author: rongcheng
 * @@后台人员: xxx
 * @Date: 2022-03-10 11:13:19
 * @LastEditors: rongcheng
 * @LastEditTime: 2022-03-10 13:04:17
 */
import Clipboard from 'clipboard'

function clipboardSuccess() {
  console.log('成功！')
  // Vue.prototype.$message.success('复制成功')
}

function clipboardError() {
  console.log('失败！')
  // Vue.prototype.$message.error('复制失败')
}

export function copy(text, event, onSuccess?, onError?) {
  event = event || {}
  const clipboard: any = new Clipboard(event.target, {
    text: () => text
  })
  clipboard.on('success', () => {
    onSuccess ? onSuccess() : clipboardSuccess()
    clipboard.off('error')
    clipboard.off('success')
    clipboard.destroy()
  })
  clipboard.on('error', () => {
    onSuccess ? onError() : clipboardError()
    clipboard.off('error')
    clipboard.off('success')
    clipboard.destroy()
  })
  clipboard.onClick(event)
}
