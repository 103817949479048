
import { defineComponent, reactive, toRefs, ref, watch } from 'vue'
import { setEnterpriseRole, getRoleList } from './api'
// import { treeData } from '@/utils/index'
import { toResult } from '@/utils/toResult'
import { ElMessage } from 'element-plus'
export default defineComponent({
  props: {
    rowData: Object
  },
  setup(props) {
    const dialogVisible = ref(false)
    const data = reactive({
      tree: null,
      form: {
        role: '',
        enterpriseCode: '',
        enterpriseName: '',
        accountId: '',
        enterpriseId: '',
        accountName: '',
        companyUuid: '',
        checkedCities: []
      },
      formRef: null,
      rules: {
        role: [{
          required: true,
          message: '必填',
          trigger: 'change'
        }]
      },
      cities: []
    })
    const handleClose = () => {
      dialogVisible.value = false
    }
    const handleSubmit = () => {
      data.formRef.validate(async(valid) => {
        if (!valid) return false
        const p = {
          companyUuid: data.form.companyUuid,
          platformCode: 2,
          roleId: data.form.role,
          accountId: data.form.accountId
        }
        const [err, res, msg] = await toResult(setEnterpriseRole(p))
        if (err) return false
        console.log(res)
        ElMessage.success(msg)
        handleClose()
      })
      // // 勾选参数
      // const permissions = []
      // data.tree.getCheckedNodes().forEach(item => {
      //   permissions.push({
      //     type: item.type,
      //     menuId: item.parentId, // 父级id
      //     menuOperationId: item.id // 自身id
      //   })
      // })
      // PermissionUpdateRole({
      //   accountId: props.rowData.accountId,
      //   permissions: JSON.stringify(permissions)
      // }).then((res: any) => {
      //   ElMessage.success(res.msg)
      //   dialogVisible.value = false
      // })
    }
    const getDetail = (row) => {
      getRoleList({
        presetRole: 1,
        roleType: 1,
        platformCode: 2,
        companyId: row.enterpriseUuid
      }).then(res => {
        console.log(res)
        data.form.enterpriseCode = res.data.enterpriseCode
        data.form.enterpriseName = res.data.enterpriseName
        data.form.accountName = res.data.accountName
        data.form.accountId = res.data.accountId
        data.form.companyUuid = res.data.companyUuid
        data.form.role = res.data.presetRoleName
        data.form.enterpriseId = res.data.enterpriseId as string
        data.cities = res.data.voList
      })
      console.log(row)
      // detailOfAccountPermission({
      //   uuid: row?.accountId
      // }).then(res => {
      //   // console.log(form.value)
      //   data.form = Object.assign(data.form, res.data)
      //   // console.log(form, 'form')
      //   data.keys = []
      //   // const list = []
      //   res.data.permissionList.forEach(item => {
      //     if (item.hasAuth) {
      //       data.keys.push(item.labelId)
      //     }
      //     // list.push({
      //     //   type: item.type,
      //     //   id: item.labelId,
      //     //   label: item.labelName,
      //     //   parentId: item.parentId
      //     // })
      //   })

      // data.data = treeData(list)
      // data.tree.setCheckedKeys(data.keys)
      // })
    }
    watch(() => props.rowData, (n) => {
      if (n) {
        data.form.role = null
        getDetail(n)
      }
    })
    return {
      dialogVisible,
      ...toRefs(data),
      handleSubmit,
      handleClose
    }
  }
})
