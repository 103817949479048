
import { defineComponent, reactive, ref, toRefs, onMounted, watch } from 'vue'
// import { ElMessage } from 'element-plus'
import { resetPassword } from '../api'
import { toResult } from '@/utils/toResult'
import { copy } from '@/utils/clipboard'
// import Clipboard from 'clipboard'
export default defineComponent({

  props: {
    visible: Boolean,
    rowData: Object
  },
  setup(props, { emit }) {
    const data = reactive({
      formRef: ref<HTMLElement | null>(null),
      copyRef: ref<HTMLElement | null>(null),
      formData: ref<any>({}),
      isDialog: props.visible
    })
    const options = ref([])
    const rules = {
      vin: [
        { required: true, message: '必填', trigger: 'blur' }
        // {
        //   pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{2,25}$/
        //   message: '只支持输入最多25位数字、字母组合'
        //   trigger: 'blur'
        // }
      ],
      changeTime: [{ required: true, message: '必填', trigger: 'blur' }]
    }
    const resetPasswordApi = async() => {
      console.log(props)
      const p = {
        uuid: props.rowData.accountId
      }
      const [err, res] = await toResult(resetPassword(p))
      if (err) return false
      data.formData = res
      // console.log(err, res, '111')
    }
    // 关闭弹框
    const handleCloseDialog = () => {
      emit('close-dialog', false)
    }

    const handleCopy = (e) => {
      copy(`账户:${data.formData.accountName},密码:${data.formData.password}`, e)
    }
    const remoteMethod = value => {
      options.value = []
      if (value !== '') {
      }
    }
    // 保存提交数据
    const submitData = (e) => {
      handleCopy(e)
      handleCloseDialog()
      // const ref: any = data.formRef
      // ref.validate((valid: boolean) => {
      //   if (valid) {
      //   }
      // })
    }
    watch(() => props.visible, (n: boolean) => {
      // console.log(n)
      data.isDialog = n
      // console.log(n, props, 'n')
      n && resetPasswordApi()
    })
    onMounted(() => {

    })

    return {
      ...toRefs(data),
      options,
      rules,
      handleCopy,
      handleCloseDialog,
      submitData,
      remoteMethod
    }
  }
})
