
import { defineComponent, reactive, ref, toRefs, onMounted, watch } from 'vue'
import { ElMessage } from 'element-plus'
import { setUpdateName } from '../api'

import { toResult } from '@/utils/toResult'
export default defineComponent({

  props: {
    visible: Boolean,
    rowData: Object
  },
  setup(props, { emit }) {
    const prop = props
    const data = reactive({
      formRef: ref<HTMLElement | null>(null),
      formData: ref<any>({}),
      isDialog: props.visible
    })
    watch(() => props.visible, (n: boolean) => {
      // console.log(n)
      data.isDialog = n
    })
    // watchEffect(() => {
    //   console.log(props)
    // })
    const options = ref([])
    const rules = {
      name: [
        { required: true, message: '必填', trigger: 'blur' }
        // {
        //   pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{2,25}$/
        //   message: '只支持输入最多25位数字、字母组合'
        //   trigger: 'blur'
        // }
      ],
      changeTime: [{ required: true, message: '必填', trigger: 'blur' }]
    }

    // 关闭弹框
    const handleCloseDialog = () => {
      data.isDialog = false
      emit('close-dialog', false)
    }

    const remoteMethod = value => {
      options.value = []
      if (value !== '') {
      }
    }
    // 保存提交数据
    const submitData = () => {
      const ref: any = data.formRef
      ref.validate(async(valid: boolean) => {
        if (!valid) {
          return false
        }
        const p = {
          accountId: prop.rowData.accountId,
          accountName: data.formData.name
        }
        const [err, response] = await toResult(setUpdateName(p))
        if (err) return false
        // console.log(response, '22')
        ElMessage.success('修改成功！')
        handleCloseDialog()
        console.log(response)
      })
    }

    onMounted(() => {
    })

    return {
      ...toRefs(data),
      options,
      prop,
      rules,
      handleCloseDialog,
      submitData,
      remoteMethod
    }
  }
})
