/*
 * @Description:
 * @Author: rongcheng
 * @@后台人员: xxx
 * @Date: 2021-04-07 15:28:18
 * @LastEditors: rongcheng
 * @LastEditTime: 2022-04-01 17:18:34
 */
import service from '@/utils/request'

// 列表
export const getList = (params: any) =>
  service.request({
    method: 'post',
    url: 'admin/account/getPageOfEnterpriseAccount',
    data: params
  })

// 禁止
export const disableAccountApi = (params: any) =>
  service.request({
    method: 'post',
    url: 'admin/account/disable',
    data: params
  })

// 启动
export const enableAccountApi = (params: any) =>
  service.request({
    method: 'post',
    url: 'admin/account/enable',
    data: params
  })
// 重置密码
export const resetPasswordAccountApi = (params: any) =>
  service.request({
    method: 'post',
    url: 'admin/account/resetPassword',
    data: params
  })
// 管理员权限详情
export const detailOfAccountPermission = (params: any) =>
  service.request({
    method: 'post',
    url: 'admin/account/detailOfAccountPermission',
    data: params
  })
// 管理员下拉框
export const dropdownOfEnterprise = (params: any) =>
  service.request({
    method: 'post',
    url: 'admin/enterprise/dropdownOfEnterprise',
    data: params
  })

/// 管理员权限 修改
export const PermissionUpdateRole = (pramas: any) =>
  service.request({
    method: 'post',
    url: 'admin/role/update/enterprise/role',
    data: pramas
  })
// 管理员 账号名修改
export const setUpdateName = (pramas: any) =>
  service.request({
    method: 'post',
    url: '/admin/account/modifyAccName',
    data: pramas
  })
// 管理员 密码重置
export const resetPassword = (pramas: any) =>
  service.request({
    method: 'post',
    url: '/admin/account/resetPassword',
    data: pramas
  })
// 获取预置角色下拉框
export const getRoleList = (params: any) =>
  service.request({
    method: 'post',
    url: '/admin/preset/role/list',
    data: params
  })
// 预置管理员角色
export const setEnterpriseRole = (params: any) =>
  service.request({
    method: 'post',
    url: '/admin/account/updateEnterpriseSuperPresetRole',
    data: params
  })
