
export const getTableConfig = () => {
  // table 通用属性
  const common = {
    // table 有多选时设置
    isSelection: false,
    // table loading
    loading: false,
    // table 是否为斑马纹
    stripe: true,
    // table 的尺寸 medium / small / mini
    size: 'small',
    // table 是否开启索引
    isIndex: true,
    // // table 索引 label
    indexLabel: '序号',
    // table 数据
    data: []
  }

  // table 分页
  const pagination = {
    // 是否展示分页
    isShow: true,
    // 总条数
    total: 0,
    // 每页显示条目个数
    pageSize: 20,
    // 当前页数
    currentPage: 1,
    // 每页显示个数选择器的选项设置
    pageSizes: [10, 20, 40, 80, 100]
  }

  // table 列数据
  const column = [
    {
      key: 'enterpriseCode',
      label: '企业编号',
      width: '140'
    },
    {
      key: 'enterpriseName',
      label: '企业名称',
      width: '140'
    },
    {
      key: 'accountName',
      label: '管理员账号',
      width: '140'
    },
    {
      key: 'accountStatusText',
      label: '状态',
      width: '140'
    },
    {
      key: 'operator',
      label: '操作人',
      width: '140'
    },
    {
      key: 'updateTime',
      label: '更新时间',
      width: '140'
    },
    {
      key: 'action',
      label: '操作',
      width: '200',
      fixed: 'right',
      slot: 'action'
    }
  ]

  return {
    ...common,
    pagination,
    column
  }
}

// 页面搜索和基础变量
export const getIndexConfig = (remoteData: any) => {
  // 搜索变量
  const inputList = [
    {
      type: 'input',
      label: '企业编号',
      key: 'enterpriseCode',
      placeholder: '请输入',
      labelWidth: 100,
      inputWidth: 200,
      maxlength: 18,
      rules: {
      }
    },
    {
      type: 'select',
      label: '企业名称',
      key: 'companyId',
      placeholder: '请输入',
      labelWidth: 100,
      inputWidth: 200,
      maxlength: 50,
      options: remoteData.options,
      isLoading: remoteData.isLoading,
      isRemote: true,
      isMultiple: false,
      isFilterable: true,
      isCollapseTags: false,
      remoteMethod: remoteData.remoteMethod,
      rules: {
      }
    },

    {
      type: 'input',
      label: '管理员账号',
      key: 'accountName',
      placeholder: '请输入',
      labelWidth: 100,
      inputWidth: 200,
      maxlength: 16,
      rules: {
      }
    },
    {
      type: 'select',
      label: '状态',
      key: 'accountStatus',
      placeholder: '请选择',
      labelWidth: 100,
      inputWidth: 200,
      options: [
        {
          label: '正常',
          value: 1
        }, {
          label: '禁用',
          value: 0
        }
      ],
      rules: {
        type: 'number'
      }
    }
  ]

  // 页面基础变量
  const page = {
    queryForm: {}, // 搜索参数
    visible: false, // 弹框显示
    rowData: null // 单行数据
  }

  return {
    inputList,
    ...page
  }
}
